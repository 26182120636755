import * as React from 'react';
import cx from 'classnames';
import type { Argument } from 'classnames';
import { useWindowSize, useResizeObserver } from 'usehooks-ts';
import { BREAKPOINTS } from '@dx-ui/osc-responsive-image';
import { useTranslation } from 'next-i18next';
import { TabListButton } from '../tab.list.button';
import { useForkedRef } from '@dx-ui/utilities-use-forked-ref';
import { Icon } from '@dx-ui/osc-icon';
import { isRtl as isRTL } from '@dx-ui/utilities-get-language-direction';
import { useRect } from '@dx-ui/utilities-use-rect';
import { useRouter } from 'next/router';

type TabListScrollable = React.HTMLAttributes<HTMLDivElement> & {
  leftArrowWrapperClassName?: Argument;
  rightArrowWrapperClassName?: Argument;
};

const TabListScrollable = React.forwardRef<HTMLDivElement, TabListScrollable>(
  (
    { className, leftArrowWrapperClassName, rightArrowWrapperClassName, children, id, ...rest },
    forwardedRef
  ) => {
    const arrayChildren = React.Children.toArray(children);
    const { width = 0 } = useWindowSize();
    const { t } = useTranslation('osc-scrollable-tabs');
    const { locale = 'en' } = useRouter();
    const isRtl = isRTL(locale);
    const tablistRef = React.useRef<React.ElementRef<'div'>>(null);
    const ref = useForkedRef(forwardedRef, tablistRef);
    const [isBackwardArrowVisible, setBackwardArrowVisible] = React.useState(false);
    const [isForwardArrowVisible, setForwardArrowVisible] = React.useState(false);

    const [focusedTabId, setFocusedTabId] = React.useState<string | null>(null);

    //The purpose of this is to adjust the scroll position of the selected tab when switching between tabs adds vertical scrollbar to the screen.
    useResizeObserver({
      ref: tablistRef,
      box: 'border-box',
      onResize: () => {
        if (tablistRef.current && focusedTabId) {
          const focusedTab = document.getElementById(focusedTabId);

          if (focusedTab) {
            const { scrollLeft } = tablistRef.current;
            //excluding padding of the Scrollable Div
            //The distance from the beginning of the tablist div to the left border of the focused button
            const distanceFromButtonToBeginningOfTabListDiv =
              focusedTab.offsetLeft - tablistRef.current.scrollLeft;
            const buttonWidth = focusedTab.offsetWidth;
            //excluding scrollable tabs
            const tablistWidth = tablistRef.current.offsetWidth;

            tablistRef.current.scrollLeft = adjustScrollPosition(
              distanceFromButtonToBeginningOfTabListDiv,
              buttonWidth,
              tablistWidth,
              scrollLeft
            );
          }
        }
      },
    });

    const updateArrowVisibility = React.useCallback(
      (rect: DOMRect | undefined) => {
        //number of pixels that an element's content is scrolled from its left edge
        const scrollLeft = tablistRef.current?.scrollLeft ?? 0;
        // measurement of the width of an element's content, including content not visible on the screen due to overflow
        const scrollWidth = tablistRef.current?.scrollWidth ?? 0;
        //width of the tablist div excluding hidden tabs due to scroll
        const tabListWidth = rect?.width || 0;
        //indicates whether we need a scroll or not
        const widthDiff = scrollWidth - tabListWidth;
        //widthDiff > 0 means we need arrows
        //for LTR, checking if scrollLeft value is more than 5 pixels, that means we need to let the user scroll backwards using arrows.
        //similar is being checked for RTL.
        const showScrollBackwardArrow = isRtl
          ? scrollLeft < -5 && widthDiff > 0
          : scrollLeft > 5 && widthDiff > 0;
        //widthDiff > 0 means we need arrows
        //for LTR, once widthDiff is almost the same as scrollLeft that means we are close to the end of the scroll.
        //For the last 5 pixels of the scroll the arrow dissapears.
        //similar is being checked for RTL.
        const showScrollForwardArrow = isRtl
          ? widthDiff > 0 && widthDiff > -scrollLeft + 5
          : widthDiff > 0 && widthDiff - scrollLeft > 5;

        setBackwardArrowVisible(showScrollBackwardArrow);
        setForwardArrowVisible(showScrollForwardArrow);
      },
      [isRtl]
    );

    const tabListRect = useRect({ ref: tablistRef, onResize: updateArrowVisibility });
    const onTabListScroll = React.useCallback(
      () => tabListRect && updateArrowVisibility(tabListRect),
      [updateArrowVisibility, tabListRect]
    );

    const threeItems = arrayChildren.length === 3;
    const fourItems = arrayChildren.length === 4;

    const getScrollMultiplier = () => {
      //Checking if there are 3 or 4 tabs or the device is tablet or mobile.
      if (threeItems || fourItems || width <= BREAKPOINTS['lg']) {
        // scroll 50% of the offset width when there are less items or on smaller screens
        return 0.5;
      } else {
        // at 40% of the offset width, each click scrolls a smaller distance. This works better for larger screens or higher item counts
        return 0.4;
      }
    };

    const scrollForward = (scrollLeft: number, offsetWidth: number) => {
      return scrollLeft + offsetWidth * getScrollMultiplier();
    };
    const scrollBackward = (scrollLeft: number, offsetWidth: number) => {
      return scrollLeft - offsetWidth * getScrollMultiplier();
    };

    const rightArrowHandler = () => {
      if (tablistRef.current) {
        const { scrollLeft, offsetWidth } = tablistRef.current;
        if (isRtl) {
          tablistRef.current.scrollLeft = scrollBackward(scrollLeft, offsetWidth);
        } else {
          tablistRef.current.scrollLeft = scrollForward(scrollLeft, offsetWidth);
        }
      }
    };

    const leftArrowhandler = () => {
      if (tablistRef.current) {
        const { scrollLeft, offsetWidth } = tablistRef.current;
        if (isRtl) {
          tablistRef.current.scrollLeft = scrollForward(scrollLeft, offsetWidth);
        } else {
          tablistRef.current.scrollLeft = scrollBackward(scrollLeft, offsetWidth);
        }
      }
    };

    function adjustScrollPosition(
      distanceFromButtonToBeginningOfTabListDiv: number,
      buttonWidth: number,
      tablistWidth: number,
      scrollLeft: number
    ) {
      //the padding value in rems
      const paddingLeft = 0.25; //the "px-1" class. paddingLeft = paddingRight
      const leftPaddingOfScrollableDiv =
        paddingLeft * parseFloat(getComputedStyle(document.documentElement).fontSize);
      //Adjusting the scroll position if the focused button is not fully visible to the user
      //This if statement checks if the focused button overflows to the right(forwards) of the tablist div
      //if it is, the scroll position is adjusted to make focused button fully visible.
      if (
        distanceFromButtonToBeginningOfTabListDiv + buttonWidth >=
        tablistWidth - leftPaddingOfScrollableDiv
      ) {
        return (
          scrollLeft +
          (distanceFromButtonToBeginningOfTabListDiv +
            buttonWidth +
            leftPaddingOfScrollableDiv -
            tablistWidth)
        );
      }
      //Adjusting the scroll position if the focused button is not fully visible to the user
      //This if statement checks if the focused button overflows to the left(backwards) of the tablist div
      //if it is, the scroll position is adjusted to make focused button fully visible.
      if (distanceFromButtonToBeginningOfTabListDiv < leftPaddingOfScrollableDiv) {
        return (
          scrollLeft - (leftPaddingOfScrollableDiv - distanceFromButtonToBeginningOfTabListDiv)
        );
      }

      return scrollLeft;
    }

    const scrollToFocusedItem = (e: React.FocusEvent<HTMLButtonElement>) => {
      setFocusedTabId(e.target.id);
      if (tablistRef.current) {
        const { scrollLeft } = tablistRef.current;
        //excluding padding of the Scrollable Div
        //The distance from the beginning of the tablist div to the left border of the focused button
        const distanceFromButtonToBeginningOfTabListDiv =
          e.target.offsetLeft - tablistRef.current.scrollLeft;
        const buttonWidth = e.target.offsetWidth;
        //excluding scrollable tabs
        const tablistWidth = tablistRef.current.offsetWidth;

        tablistRef.current.scrollLeft = adjustScrollPosition(
          distanceFromButtonToBeginningOfTabListDiv,
          buttonWidth,
          tablistWidth,
          scrollLeft
        );
      }
    };

    if (arrayChildren.length < 1) return null;
    const lessOrEqual5 = arrayChildren.length <= 5;
    const lessOrEqual2 = arrayChildren.length <= 2;

    return (
      //28px is the width of the svg arrow icon
      <div
        className={cx('relative', {
          'mx-[28px] 2xl:m-0': !lessOrEqual5,
          'mx-[28px] lg:m-0': lessOrEqual5 && !lessOrEqual2,
          'm-0': lessOrEqual2,
        })}
      >
        <div
          role="tablist"
          className={cx(
            'flex justify-start overflow-x-auto px-1 pt-2 motion-safe:scroll-smooth',
            className
          )}
          ref={ref}
          onScroll={onTabListScroll}
          aria-labelledby={id}
          {...rest}
        >
          {isBackwardArrowVisible ? (
            <div
              className={cx(
                'from-bg pointer-events-none absolute start-0 z-10 flex h-full w-1/5 -translate-x-[28px] justify-start bg-gradient-to-r pb-3 lg:w-80 rtl:translate-x-[28px] rtl:bg-gradient-to-l',
                { 'block lg:hidden': lessOrEqual5 && !lessOrEqual2, hidden: lessOrEqual2 },
                leftArrowWrapperClassName
              )}
            >
              <button
                className="pointer-events-auto"
                onClick={leftArrowhandler}
                aria-hidden
                tabIndex={-1}
                data-testid="scrollBackward"
                type="button"
              >
                {isRtl ? (
                  <Icon name="arrowhead-right" size="md" />
                ) : (
                  <Icon name="arrowhead-left" size="md" />
                )}
                <span className="sr-only">{t('scrollBackward')}</span>
              </button>
            </div>
          ) : null}

          {React.Children.map(arrayChildren, (child) => {
            if (React.isValidElement(child) && child.type === TabListButton)
              return React.cloneElement(child as React.ReactElement, {
                onFocus: (e: React.FocusEvent<HTMLButtonElement>) => {
                  child.props?.onFocus?.();
                  scrollToFocusedItem(e);
                },
              });
          })}

          {isForwardArrowVisible ? (
            <div
              className={cx(
                'from-bg pointer-events-none absolute end-0 flex h-full w-1/5 translate-x-[28px] justify-end bg-gradient-to-l pb-3 lg:w-80 rtl:-translate-x-[28px] rtl:bg-gradient-to-r',
                { 'block lg:hidden': lessOrEqual5 && !lessOrEqual2, hidden: lessOrEqual2 },
                rightArrowWrapperClassName
              )}
            >
              <button
                onClick={rightArrowHandler}
                className="pointer-events-auto"
                aria-hidden
                tabIndex={-1}
                data-testid="scrollForward"
                type="button"
              >
                {isRtl ? (
                  <Icon name="arrowhead-left" size="md" />
                ) : (
                  <Icon name="arrowhead-right" size="md" />
                )}
                <span className="sr-only">{t('scrollForward')}</span>
              </button>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
);

TabListScrollable.displayName = 'TabListScrollable';

export { TabListScrollable };
export default TabListScrollable;
